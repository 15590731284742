import React, { useState, useEffect } from 'react';
import ContentModal from './ContentModal';
import './SidebarStyles.css';
import axios from 'axios';
import { CONTENT_API_ROOT, VCAPI_ROOT, WHITEBOARD_APP_ROOT, SCHEDULE_API_ROOT } from "../config";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShrinkOutlined,
  StopOutlined,
  RocketOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  EditOutlined,
  GoogleOutlined,
  VideoCameraOutlined,
  FileOutlined
} from '@ant-design/icons';
import { Button, Menu } from 'antd';

const Sidebar = ({ 
  isOpen, 
  toggleSidebar, 
  accessToken, 
  startHBCollaborate, 
  isCollaborate,
  stopHBCollaborate,
  updateHyperbeamUrl,
  startVideoContentSharing,
  stopVideoContentSharing,
  isVideoContentShared,
  meetingId
}) => {
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [materialsType, setMaterialsType] = useState("");
  const [publishedTo, setPublishedTo] = useState("");
  const [contentList, setContentList] = useState([]);
  const [contentModalTitle, setContentModalTitle] = useState("Content Library");
  const [searchQuery, setSearchQuery] = useState("");
  const [isContentLoading, setIsContentLoading] = useState(false);
  const itemsPerPage = 24;
  const [itemsCount, setItemsCount] = useState(0);
  const [meetingFiles, setMeetingFiles] = useState([]);
  const defaultContentTab = 'XLIndia';

  const getMeetingFiles = () => {
    axios.get(`${SCHEDULE_API_ROOT}/content-list/${meetingId}`)
    .then(function (response) {
      const meetingFilesList = response?.data?.result || [];
      setMeetingFiles(meetingFilesList);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  useEffect(() => {
    if(publishedTo){
      handleContentButtonClick(1);
    }
    getMeetingFiles();
      
  }, [publishedTo, materialsType]);

  const updateContentParams = (contentMaterialType, contentPublishedTo) => {
    let modalTitle = contentModalTitle;
    switch (contentMaterialType) {
      case "LEARNING MATERIALS,PRESENTATIONS":
        modalTitle = "Content Library";
        break;
      case "WEB APP":
        modalTitle = "Web-Apps";
        break;
      case "GAMES":
        modalTitle = "Games";
        break;
      case "VIDEO LIBRARY":
        modalTitle = "Video Library";
        break;
    
      default:
        break;
    }
    setMaterialsType(contentMaterialType);
    setPublishedTo(contentPublishedTo);
    setContentModalTitle(modalTitle);
  };

  const handlePageChange = page => {
    handleContentButtonClick(page);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const requestHeaders = {
    'Content-Type': 'application/json',
    'Authorization': accessToken || null,
  };

  const handleContentButtonClick = (pageNumber) => {
    const page = pageNumber ?? 1;
    setItemsCount(0);
    setIsContentModalOpen(true);
    setContentList([]);
    setIsContentLoading(true);
    //let materialsTypeQuery = (materialsType == 'WEB APP' || materialsType == 'GAMES')?materialsType:"DOC,PPT,PDF,Image";
    axios.post(`${CONTENT_API_ROOT}/api/v1/content/list2`, {
      page : page,
      pageSize : itemsPerPage,
      search	: searchQuery,
      publishedTo : publishedTo,
      contentTypeCodes : materialsType.split(","),
      domainIds : []      
    }, { headers : requestHeaders })
    .then(function (response) {
      const learningMaterials = response?.data?.contents;
      const totalRecords = response?.data?.totalContents || 0;
      setContentList(learningMaterials);
      setItemsCount(totalRecords);
      setIsContentLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const closeModal = () => {
    setIsContentModalOpen(false);
    setSearchQuery("");
    setPublishedTo(null);
  };

  const handleItemClick = (content) => {
    let contentUrl;
    let fileUrl = content.contentUrl ?? content.fileUrl;
    if(content === 'whiteboard'){
      contentUrl = `${WHITEBOARD_APP_ROOT}`;
    }
    else if(content === 'collaborate'){
      contentUrl = `https://google.com`;
    }
    else if(content.contentTypeCode == 'WEB APP' 
      || content.contentTypeCode == 'GAMES' 
      || content.contentTypeCode == 'VIDEO LIBRARY'
      || content.type === 'youtube'){
      contentUrl = fileUrl;
    }
    else if(content.fileType == 'PPT'){
      contentUrl = `${VCAPI_ROOT}/site/embed-ppt-iframe?ppt=${fileUrl}`;
    }
    else if(content.fileType == 'PDF'){
      contentUrl = `${WHITEBOARD_APP_ROOT}?pdfQuery=${encodeURIComponent(fileUrl)}`;
    }
    else{
      alert("File-type not supported in current version!");
      return;
    }
    if(content.contentTypeCode == 'VIDEO LIBRARY' || content.type === 'youtube'){
      startVideoContentSharing(contentUrl);
    }
    else{
      startHBCollaborate(contentUrl);
    }
    closeModal();
    toggleSidebar()
  };

  const getMenuItems = () => {
    let menuItems = [];

    if(isCollaborate)
      menuItems.push({
        label: "Stop Collaborate",
        key: 'stopCollaborate',
        icon: <StopOutlined />,
        danger : true,
        className: "danger",
        onClick: () => stopHBCollaborate()
      });
    if(isVideoContentShared)
      menuItems.push({
        label: "Stop Video-Content Sharing",
        key: 'stopVideoContentSharing',
        icon: <StopOutlined />,
        danger : true,
        onClick: () => stopVideoContentSharing()
      });
    
    if(isCollaborate || isVideoContentShared)
      menuItems.push({
        type: 'divider',
      });
      
    if(meetingFiles.length > 0){
      meetingFiles.map(meetingFile => {
        menuItems.push({
          label: meetingFile.title,
          key: `meetingFile${meetingFile.id}`,
          icon: <FileOutlined />,
          onClick: () => handleItemClick(meetingFile)
        });
      });
      menuItems.push({
        type: 'divider',
      });
    }
    
    if(!isVideoContentShared){
      const contentItems = [
        {
          label: "Collaborate",
          key: 'Collaborate',
          icon: <GoogleOutlined />,
          onClick: () => handleItemClick("collaborate"),
        },
        {
          label: "Whiteboard",
          key: 'Whiteboard',
          icon: <EditOutlined />,
          onClick: () => handleItemClick("whiteboard"),
        },
        {
          label: "Content Library",
          key: 'LearningMaterials',
          icon: <FilePdfOutlined />,
          onClick: () => updateContentParams("LEARNING MATERIALS,PRESENTATIONS", defaultContentTab),
        },
        {
          label: "Web-Apps",
          key: 'WebApps',
          icon: <ShrinkOutlined />,
          onClick: () => updateContentParams("WEB APP", defaultContentTab),
        },
        {
          label: "Games",
          key: 'Games',
          icon: <RocketOutlined />,
          onClick: () => updateContentParams("GAMES", defaultContentTab),
        },        
      ];
      menuItems.push(...contentItems);
    }
    if(!isCollaborate && !isVideoContentShared)
      menuItems.push({
        label: "Video Library",
        key: 'VideoLibrary',
        icon: <VideoCameraOutlined />,
        onClick: () => updateContentParams("VIDEO LIBRARY", defaultContentTab)
      });
    return menuItems;
  };

  return (
    <>
    <Button
      type="primary"
      onClick={toggleSidebar}
      className="sidebar-toggle"
      >
      {isOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </Button>
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-content">
        <Menu items={getMenuItems()} mode="inline" theme="dark" defaultSelectedKeys={[]} />
      </div>
      {isContentModalOpen && <ContentModal 
        handleSearchInputChange={handleSearchInputChange} 
        handlePageChange={handlePageChange} 
        itemsCount={itemsCount} 
        itemsPerPage={itemsPerPage} 
        isContentLoading={isContentLoading} 
        stopHBCollaborate={stopHBCollaborate} 
        isCollaborate={isCollaborate} 
        contentList={contentList} 
        closeModal={closeModal} 
        handleItemClick={handleItemClick} 
        searchText={searchQuery}
        materialsType={materialsType}
        publishedTo={publishedTo}
        setPublishedTo={setPublishedTo}
        contentModalTitle={contentModalTitle}
        defaultContentTab={defaultContentTab}
        />}
    </div>
    </>
  );
};

export default Sidebar;