import React, { useState } from 'react';
import axios from 'axios';
import './ContentModalStyles.css';
import ContentList from './ContentList';
import ContentPagination from './ContentPagination';
import { Button, Tabs } from 'antd';
import { SearchOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { YOUTUBE_API_KEY } from "../config";

const ContentModal = ({ 
  contentList, 
  closeModal, 
  handleItemClick, 
  isCollaborate, 
  stopHBCollaborate, 
  isContentLoading, 
  itemsCount, 
  itemsPerPage,
  handlePageChange,
  handleSearchInputChange,
  searchText,
  materialsType,
  publishedTo,
  setPublishedTo,
  contentModalTitle,
  defaultContentTab
  }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(itemsCount / itemsPerPage);
  const [activeTab, setActiveTab] = useState(defaultContentTab);
  const [youtubeResults, setYoutubeResults] = useState([]);
  const [youtubeSearching, setYoutubeSearching] = useState(false);
  const [showVideoPreviewModal, setShowVideoPreviewModal] = useState(false);
  const [currentPreviewVideo, setCurrentPreviewVideo] = useState(null);

  const handleYoutubePreview = (video) => {
    setCurrentPreviewVideo(video);
    setShowVideoPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowVideoPreviewModal(false);
    setCurrentPreviewVideo(null);
  };
  
  const handlePageClick = (number) => {
    setCurrentPage(number);
    handlePageChange(number);
  };

  const handleSearch = () => {
    if (activeTab === 'youtube') {
      searchYoutubeVideos();
    }
    else{
      handlePageChange(1);
    }
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleTabChange = (key) => {
    setCurrentPage(1);
    setActiveTab(key);
    if (key !== 'youtube') {
      setPublishedTo(key);
    }
  };

  const searchYoutubeVideos = () => {
    const maxResults = 100; // You can adjust the number of results to fetch
    const searchQuery = searchText.trim();
    setYoutubeSearching(true);
    axios
      .get('https://www.googleapis.com/youtube/v3/search', {
        params: {
          key: YOUTUBE_API_KEY,
          part: 'snippet',
          q: searchQuery,
          maxResults: maxResults,
          type: 'video',
          videoEmbeddable: true,
          videoCategoryId: '27',
          safeSearch: 'strict'
        },
      })
      .then((response) => {
        console.log(response.data.items);
        setYoutubeResults(response.data.items);
        setYoutubeSearching(false);
      })
      .catch((error) => {
        setYoutubeSearching(false);
        console.error('Error fetching YouTube videos:', error);
      });
  };

  const getTabItems = () => {
    const contentTabs = ['MYLIB', 'MYORG', 'XLIndia', 'XL'];
    const contentTabLabels = {'XL':"Global Library", 'XLIndia':"India Library", 'MYLIB':"My Library", 'MYORG':"My Organization Library" };
    let tabItems = [];
    contentTabs.map(publishedToName => (
      tabItems.push({
          key: publishedToName,
          label: contentTabLabels[publishedToName],
          children: <>
            <ContentList isContentLoading={isContentLoading} contentList={contentList} handleItemClick={handleItemClick} />
            <ContentPagination currentPage={currentPage} pageCount={pageCount} handlePageClick={handlePageClick} />
          </>
        })
    ));
    if(materialsType === 'VIDEO LIBRARY'){
      tabItems.push({
        key: 'youtube',
        label: `Youtube`,
        children: <>
          {!youtubeSearching?
            youtubeResults.length > 0?
            <ul className="content-list">
              {youtubeResults.map(video => (
                <li key={video.id.videoId} >
                  <img onClick={() =>handleItemClick({type :'youtube', contentUrl : `https://www.youtube.com/watch?v=${video.id.videoId}`}) } src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                  <span className="content-title">{video.snippet.title}</span>
                  <button className="preview-button" onClick={() => handleYoutubePreview(video)}>
                    Preview
                  </button>
                </li>
              ))}
            </ul> : <center>{"No results found!"}</center>
          : <center>{"Searching Youtube..."}</center>}
        </>,
      });
    }
    return tabItems;
  };
  

  
  
  return (
    <div className="modal">
      <div className="modal-content">
        {isCollaborate?<button className='closeCollabBtn' onClick={stopHBCollaborate}>Stop Collaboration</button>:null}
        <Button
          className="closeBtn"
          onClick={closeModal}
          icon={<CloseCircleOutlined />}
        />
        <h2 className="contentModalHeading">{contentModalTitle}</h2>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            className="search-input"
            onChange={handleSearchInputChange}
            onKeyDown={handleSearchOnEnter}
          />
          <Button
            className="search-button"
            onClick={() => handleSearch()}
            icon={<SearchOutlined />}
          />
        </div>
        <Tabs items={getTabItems()} activeKey={activeTab} onChange={handleTabChange} />

        {showVideoPreviewModal && currentPreviewVideo?
          <section class="video-modal">
            <div id="video-modal-content" class="video-modal-content">
              <iframe 
                  title={currentPreviewVideo.snippet.title}
                  id="youtube" 
                  width="100%" 
                  height="100%" 
                  frameborder="0" 
                  allow="autoplay" 
                  allowfullscreen 
                  src={`https://www.youtube.com/embed/${currentPreviewVideo.id.videoId}?autoplay=1&rel=0`}
                />
                <a href="#" class="close-video-modal"><CloseOutlined onClick={handleClosePreviewModal} /></a>
            </div>
            <div class="overlay"></div>
          </section>:null}

      </div>
    </div>
  );
};

export default ContentModal;
